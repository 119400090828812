






import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

import { Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Cluster, Vector } from 'ol/source';
import { StyleLike } from 'ol/style/Style';

export default Vue.extend({
  props: {
    styles: {
      type: [Function as () => any, Object as () => StyleLike],
    },
    name: {
      type: String as () => string,
    },
    zIndex: {
      type: Number as () => number,
    },
    cluster: {
      type: Boolean as () => boolean,
    },
  },

  data(): {
    id: string;
    layer?: VectorLayer;
    source?: Vector;
  } {
    return {
      id: uuidv4(),
      layer: undefined,
      source: undefined,
    };
  },

  computed: {
    map(): Map | undefined {
      return (this as any)._getMap();
    },
  },

  inject: {
    _getMap: {
      default: undefined,
    },
  },

  provide(): {
    _getLayer?: () => VectorLayer | undefined;
    _getSource?: () => Vector | undefined;
  } {
    return {
      _getLayer: () => this.layer,
      _getSource: () => this.source,
    };
  },

  mounted() {
    this.source = new Vector({
      features: [],
    });

    this.layer = new VectorLayer({
      source: new Cluster({
        distance: this.cluster ? 20 : 0, // distance 0 turns clustering off
        source: this.source,
      }),
      zIndex: this.zIndex,
      style: this.styles,
    });

    this.layer.setProperties({
      name: this.name,
    });
  },

  watch: {
    layer: {
      handler() {
        if (this.layer) {
          this.map?.addLayer(this.layer);
        }
      },
    },

    styles: {
      immediate: true,
      handler() {
        this.layer?.setStyle(this.styles);
      },
    },
  },

  destroyed() {
    if (this.layer) {
      this.map?.removeLayer(this.layer);
    }
  },
});
